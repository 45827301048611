import { throttle } from 'throttle-debounce';
import Toggler from '../Toggler';

const $sections = document.querySelectorAll('.js-sitemap-section');
let toggler = null;

function controlAccordion() {
  if (window.matchMedia('(max-width: 79.99em)').matches) {
    if (toggler.status === 'disabled') {
      toggler.attach();
    }
  } else if (toggler.status === 'enabled') {
    toggler.detach();
  }
}

if ($sections) {
  const togglerElements = [];

  for (let i = 0; i < $sections.length; i++) {
    togglerElements.push([$sections[i].querySelector('.js-sitemap-items'), $sections[i].querySelectorAll('.js-sitemap-toggler')]);
  }

  toggler = new Toggler(togglerElements);

  controlAccordion();
  window.addEventListener('resize', throttle(250, controlAccordion));
}
