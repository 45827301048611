import Swiper from 'swiper';
import { throttle } from 'throttle-debounce';

const $newsContainers = document.querySelectorAll('.js-news');
const $newsWrappers = document.querySelectorAll('.js-news-items');
const $newsSlides = document.querySelectorAll('.js-news-item');
let newsSwiper = null;

function init() {
  if (newsSwiper === null) {
    for (let i = 0; i < $newsContainers.length; i++) $newsContainers[i].classList.add('swiper-container');
    for (let i = 0; i < $newsWrappers.length; i++) $newsWrappers[i].classList.add('swiper-wrapper');
    for (let i = 0; i < $newsSlides.length; i++) $newsSlides[i].classList.add('swiper-slide');

    newsSwiper = new Swiper('.js-news', {
      slidesPerView: 'auto',
      roundLengths: true,
    });
  }
}

function destroy() {
  if (newsSwiper !== null) {
    newsSwiper.destroy();
    newsSwiper = null;

    for (let i = 0; i < $newsContainers.length; i++) $newsContainers[i].classList.remove('swiper-container');
    for (let i = 0; i < $newsWrappers.length; i++) $newsWrappers[i].classList.remove('swiper-wrapper');
    for (let i = 0; i < $newsSlides.length; i++) $newsSlides[i].classList.remove('swiper-slide');
  }
}

function initOrDestroy() {
  if (window.matchMedia('(min-width: 38.75em)').matches) {
    destroy();
  } else {
    init();
  }
}

if ($newsContainers.length) {
  initOrDestroy();
  window.addEventListener('resize', throttle(250, initOrDestroy));
}
