import extend from '../../../utils/extend';

/**
 * FOCUS TRAPPER
 *
 * Loop focus in a container (in a modal for example).
 */

export default class FocusTrapper {
  constructor(container, settings) {
    const _ = this;

    _.settings = extend(true, {
      selector: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])', // https://gomakethings.com/how-to-get-the-first-and-last-focusable-elements-in-the-dom/
    }, settings || {});

    _.container = container;
    _.firstFocusable = null;
    _.lastFocusable = null;
    // Global status, catching : have trapped focus, resting : focus is free
    _.state = 'resting';

    // We ensure that the target container can be focus
    if (_.container.getAttribute('tabindex') === null) {
      _.container.setAttribute('tabindex', '-1');
    }

    _.handleKeydown = _.handleKeydown.bind(_);
  }

  // Update observables elements
  update() {
    const _ = this;

    const focusables = _.container.querySelectorAll(_.settings.selector);
    const visibleFocusables = [];

    for (let i = 0; i < focusables.length; i++) {
      if (focusables[i].offsetWidth > 0 && focusables[i].offsetHeight > 0) {
        visibleFocusables.push(focusables[i])
      }
    }

    [_.firstFocusable] = visibleFocusables;
    _.lastFocusable = visibleFocusables[visibleFocusables.length - 1];
  }

  // Activate trapping
  catch() {
    const _ = this;

    _.update();

    _.container.focus();
    _.container.addEventListener('keydown', _.handleKeydown);

    _.state = 'catching';
  }

  // Deactivate trapping
  release() {
    const _ = this;

    _.container.removeEventListener('keydown', _.handleKeydown);

    _.state = 'resting';
  }

  // Detect if we need to loop forward or back
  handleKeydown(e) {
    const _ = this;

    if (e.which === 9) {
      if (e.shiftKey && (e.target === _.firstFocusable || e.target === _.container)) {
        e.preventDefault();
        _.lastFocusable.focus();
      }

      if (!e.shiftKey && e.target === _.lastFocusable) {
        e.preventDefault();
        _.firstFocusable.focus();
      }
    }
  }
}
