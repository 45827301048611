import 'core-js/features/number/is-nan';
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const murals = document.querySelectorAll('.js-mural');

if (murals.length) {
  for (let i = 0; i < murals.length; i++) {
    const mural = murals[i];
    const slider = mural.querySelector('.swiper-container');

    const swiper = new Swiper(slider, {
      spaceBetween: 5,
      loop: true,
      loopAdditionalSlides: 1,
      navigation: {
        nextEl: mural.querySelector('.swiper-button-next'),
        prevEl: mural.querySelector('.swiper-button-prev'),
      },
    });
  }
}
