import Swiper, { Navigation, Pagination } from 'swiper';
import extend from '../../../utils/extend';
import FocusTrapper from '../FocusTrapper/FocusTrapper';
import 'core-js/features/number/is-nan';

Swiper.use([Navigation, Pagination]);

export default class Gallery {
  constructor($target, $togglers, settings = {}) {
    const _ = this;

    _.$target = $target;
    _.$togglers = $togglers;

    _.settings = extend(true, {
      // The element to which append the modal
      appendTo: document.body,
      // Additional classes for styling or whatever
      additionalClasses: '',
    }, settings);

    _.$root = null;
    _.contentCopy = _.$target.innerHTML;

    _.focusTrapper = null;

    _.isOpen = false;

    _.handleEscKey = _.handleEscKey.bind(_);

    _.create();
  }

  // Build the modal
  create() {
    const _ = this;

    _.buildSkeleton();

    _.$root.querySelector('.c-gallery__close').addEventListener('click', _.close.bind(_));

    _.focusTrapper = new FocusTrapper(_.$root);
  }

  // Destory the modal
  destroy() {
    const _ = this;

    _.$root.querySelector('.c-gallery__close').removeEventListener('click', _.close.bind(_));
  }

  open() {
    const _ = this;

    _.contentCopy = _.$target.innerHTML;

    _.$root.querySelector('.c-gallery__inner').innerHTML = _.contentCopy;
    _.$target.innerHTML = '';

    _.settings.appendTo.appendChild(_.$root);

    if (_.$root.querySelectorAll('.swiper-slide').length > 1) {
      const swipers = new Swiper(_.$root.querySelector('.swiper-container'), {
        loop: true,
        spaceBetween: 10,
        pagination: {
          el: _.$root.querySelector('.swiper-pagination'),
          type: 'fraction',
        },
        navigation: {
          nextEl: _.$root.querySelector('.swiper-button-next'),
          prevEl: _.$root.querySelector('.swiper-button-prev'),
        },
      });
    } else {
      _.$root.querySelector('.swiper-button-prev').style.display = 'none';
      _.$root.querySelector('.swiper-button-next').style.display = 'none';
      _.$root.querySelector('.swiper-pagination').innerHTML = '1/1';
    }

    document.querySelector('html').style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
    document.querySelector('html').style.overflow = 'hidden';

    _.focusTrapper.catch();

    document.addEventListener('keyup', _.handleEscKey);
    _.isOpen = true;
  }

  close() {
    const _ = this;

    _.$root.querySelector('.c-gallery__inner').innerHTML = '';
    _.$target.innerHTML = _.contentCopy;

    _.focusTrapper.release();

    _.$root.parentNode.removeChild(_.$root); // Use .parentNode.removeChild() for IE 11 instead of simply .remove().

    document.querySelector('html').style.overflow = '';
    document.querySelector('html').style.paddingRight = '';

    document.removeEventListener('keyup', _.handleEscKey);

    _.isOpen = false;
  }

  buildSkeleton() {
    const _ = this;

    _.$root = document.createElement('div');
    _.$root.classList.add('c-gallery');

    _.$root.innerHTML = `
      <div class="c-gallery__inner">
      </div>
      <button class="c-gallery__close" title="Fermer"></button>
    `;
  }

  // Close when escape key is pressed
  handleEscKey(e) {
    const _ = this;

    if (_.isOpen && e.which === 27) {
      _.close();
    }
  }
}
