import 'core-js/features/number/is-nan';
import 'intersection-observer';
import { throttle } from 'throttle-debounce';
import Swiper, { Autoplay, Navigation } from 'swiper';

Swiper.use([Autoplay, Navigation]);

const sections = document.querySelectorAll('.js-logos');

if (sections.length) {
  const autoplaySpeed = 6000;
  const navSpeed = 250;
  const spaceBetween = 15;

  // Check if we really need a slider, eg: if slider area is not wide enough to display all slides
  let needSliderBehavior = function(slider) {
    const slides = slider.querySelectorAll('.swiper-slide:not(.swiper-slide-duplicate)');

    if (((slides[0].offsetWidth * slides.length) + (spaceBetween * (slides.length - 1))) > slider.offsetWidth) {
      return true;
    }

    return false;
  };

  function initSlider(slider) {
    const section = slider.parentElement;
    let translation = null;
    let hasInteractedWithDuringPause = false;

    const swiper = new Swiper(slider, {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: spaceBetween,
      freeMode: true,
      freeModeMomentum: false,
      grabCursor: true,
      roundLengths: true,
      loop: true,
      loopedSlides: 0,
      navigation: {
        nextEl: section.querySelector('.swiper-button-next'),
        prevEl: section.querySelector('.swiper-button-prev'),
      },
      autoplay: {
        delay: 1,
      },
      speed: autoplaySpeed,
    });

    // Save current translation informations
    swiper.on('slideChangeTransitionStart', (swiper) => {
      translation = { index: swiper.activeIndex, from: swiper.previousTranslate, to: swiper.translate };
    });

    // Save status if user has interacted with the slider during a autoplay's pause
    swiper.on('slideChange', (swiper) => {
      if (!swiper.autoplay.running) {
        hasInteractedWithDuringPause = true;
      }
    });

    // Pause autoplay when over section
    section.addEventListener('mouseenter', () => {
      swiper.off('transitionEnd');

      swiper.autoplay.stop();
      swiper.params.speed = navSpeed;

      const parentRect = slider.getBoundingClientRect();
      const parentLeftPadding = parseFloat(window.getComputedStyle(slider, null).getPropertyValue('padding-left'));
      const rect = slider.querySelector('.swiper-wrapper').getBoundingClientRect();

      // If we do not setup a translation to the current position, swiper will wait the end of current transition to pause the slider
      swiper.setTranslate(rect.left - parentRect.left - parentLeftPadding);

      hasInteractedWithDuringPause = false;
    });

    section.addEventListener('mouseleave', restartAutoplay);
    section.addEventListener('touchend', restartAutoplay);

    function restartAutoplay(e) {
      if (!hasInteractedWithDuringPause) {
        // If user has not interact with the slider we complete the translation that was occuring
        swiper.slideTo(translation.index, ((translation.to - swiper.getTranslate()) * autoplaySpeed) / (translation.to - translation.from));

        // And we start autoplay after this custom translation
        swiper.once('transitionEnd', () => {
          swiper.params.speed = autoplaySpeed;
          swiper.autoplay.start();
        });
      } else {
        // Else we start autoplay right now
        swiper.params.speed = autoplaySpeed;
        swiper.autoplay.start();
      }
    }

    // Pausing autoplay mixup index management, so we do a custom navigation to set it right on tracks
    swiper.params.navigation.prevEl.addEventListener('click', navigationCallback);
    swiper.params.navigation.nextEl.addEventListener('click', navigationCallback);

    function navigationCallback() {
      if (!hasInteractedWithDuringPause) {
        if (this === swiper.params.navigation.prevEl) {
          swiper.slideTo(translation.index - 1);
        } else if (this === swiper.params.navigation.nextEl) {
          swiper.slideTo(translation.index);
        }

        hasInteractedWithDuringPause = true;
      }
    }
  }

  // Use an observer to init slider when it is nearby and play/pause it
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      const swiper = entry.target.swiper;

      if (entry.isIntersecting) {
        if (!swiper && needSliderBehavior(entry.target)) {
          initSlider(entry.target);
        }
      }

      if (entry.intersectionRatio === 0 && swiper && swiper.autoplay.running) {
        swiper.autoplay.stop();
      } else if (swiper && !swiper.autoplay.running) {
        swiper.autoplay.start();
      }
    });
  }, { rootMargin: '100px 0px 100px 0px', threshold: [0, 1] });

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const slider = section.querySelector('.swiper-container');

    observer.observe(slider);

    window.addEventListener('resize', throttle(250, () => {
      if (!slider.swiper && needSliderBehavior(slider)) {
        initSlider(slider);
      } else if (slider.swiper && !needSliderBehavior(slider)) {
        slider.swiper.destroy(false);
      }
    }));
  }
}
