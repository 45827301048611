import Swiper from 'swiper';
import { throttle } from 'throttle-debounce';
import MapInterface from '../../interfaces/MapInterface';

const $locator = document.querySelector('.js-locator');
const $locatorMapContainer = $locator ? $locator.querySelector('.js-locator-map-container') : null;
let locatorIsInitalized = false;

// Tabs variables
const tabButtons = document.querySelectorAll('.js-locator-tab-button');
const resetButton = document.querySelector('.js-tab-reset');
const tabItems = document.querySelectorAll('.js-locator-slide');

let swiper = null;

function initializeSwiper() {
  if (window.matchMedia('(max-width: 1024px)').matches) {
    if (!swiper) {
      swiper = new Swiper('.js-locator-result', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          620: {
            slidesPerView: 3,
          }
        },
        spaceBetween: 20,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true
      });
    }
  } else {
    if (swiper) {
      swiper.destroy();
      swiper = null;
    }
  }
}

function updateSwiper() {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
  initializeSwiper();
}

function resetTabs() {
  $locator.classList.remove('is-active');
  tabItems.forEach(content => {
    content.classList.remove('is-active');
  });
}

function toggleTab(tab) {
  $locator.classList.add('is-active');
  tabItems.forEach(content => {
    content.classList.remove('is-active');
    if (content.getAttribute('data-type') === tab) {
      content.classList.add('is-active');
    }
  });
  if (swiper){
    updateSwiper()
  }
}

tabButtons.forEach(button => {
  button.addEventListener('click', () => {
    const tab = button.getAttribute('data-type');
    toggleTab(tab);
  });
});

document.addEventListener('DOMContentLoaded', initializeSwiper);
window.addEventListener('resize', initializeSwiper);

if (resetButton) {
  resetButton.addEventListener('click', resetTabs);
}

function locatorIsPresent() {
  return $locator && window.getComputedStyle($locatorMapContainer).display !== 'none';
}

function initLocatorMap() {
  const $map = $locator.querySelector('.js-locator-map');
  const $items = $locator.querySelectorAll('.js-locator-item');
  const places = {};

  // Init places data
  for (let i = 0; i < $items.length; i++) {
    const $item = $items[i];
    const url = $item.getAttribute('href');
    const coordinates = $item.getAttribute('data-coords') ? $item.getAttribute('data-coords').split(', ') : [0, 0];

    places[url] = {
      $item,
      url,
      type: $item.getAttribute('data-type'),
      lat: parseFloat(coordinates[0]),
      lng: parseFloat(coordinates[1]),
      marker: null,
    };
  }

  // Default bounds
  const bounds = new google.maps.LatLngBounds();

  // The map
  const map = new google.maps.Map($map, {
    center: { lat: 48.117266, lng: -1.6777926 },
    zoom: 13,
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeId: google.maps.MapTypeId.TERRAIN,
    styles: MapInterface.styles,
  });

  function getMarkerIcon(type, active = false) {
    return {
      url: `/app/themes/default/assets/images/front/locator/pins.svg#map-pin${active ? '-active' : ''}`,
      scaledSize: new google.maps.Size(45, 45),
      anchor: new google.maps.Point(22.5, 22.5),
      labelOrigin: new google.maps.Point(22.5, 22.5),
    };
  }

  function highlightItem(url) {
    places[url].marker.setIcon(getMarkerIcon(places[url].type, true));
    places[url].$item.classList.add('is-focus');
  }

  function sublightItem(url) {
    places[url].marker.setIcon(getMarkerIcon(places[url].type));
    places[url].$item.classList.remove('is-focus');
  }

  let markerGroups = [];

  Object.values(places).forEach((place) => {
    const type = place.type;
    if (!markerGroups[type]) {
      markerGroups[type] = [];
    }
    const marker = new google.maps.Marker({
      position: { lat: place.lat, lng: place.lng },
      map,
      icon: getMarkerIcon(type),
      type,
    });
    markerGroups[type].push(marker);

    places[place.url].marker = marker;

    bounds.extend({ lat: place.lat, lng: place.lng });

    place.marker.addListener('mouseover', () => {
      highlightItem(place.url);
    });

    place.marker.addListener('mouseout', () => {
      sublightItem(place.url);
    });

    place.marker.addListener('click', () => {
      window.location = place.url;
    });

    place.$item.addEventListener('mouseenter', () => {
      highlightItem(place.url);
    });

    place.$item.addEventListener('mouseleave', () => {
      sublightItem(place.url);
    });

  });

  // Tabs init

  function toggleGroup(type) {
    for (const [groupType, groupMarkers] of Object.entries(markerGroups)) {
      const isVisible = groupType === type;
      groupMarkers.forEach(marker => {
        marker.setVisible(isVisible);
      });
    }
  }

  function resetTabsMap() {
    for (const groupMarkers of Object.values(markerGroups)) {
      groupMarkers.forEach(marker => {
        console.log(marker);
        marker.setVisible(true);
      });
    }
  }

  resetButton.addEventListener('click', resetTabsMap);

  tabButtons.forEach(button => {
    button.addEventListener ('click', () => {
      const tab = button.getAttribute('data-type');
      toggleGroup(tab);
    });
  });

  MapInterface.fitBounds(map, bounds, 18, 30);

  window.addEventListener('resize', throttle(250, () => {
    if (locatorIsPresent()) {
      MapInterface.fitBounds(map, bounds, 18, 30);
    }
  }));


  locatorIsInitalized = true;

  if (swiper) {
    swiper.destroy();
  }
}

const throttleInit = throttle(250, () => {
  if (locatorIsPresent() && !locatorIsInitalized) {
    MapInterface.load(() => {
      initLocatorMap();
    });
    window.removeEventListener('resize', throttleInit);
  }
});

throttleInit();
window.addEventListener('resize', throttleInit);
