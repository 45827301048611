import 'core-js/features/number/is-nan';
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const sections = document.querySelectorAll('.js-quotes');

if (sections.length) {
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const slider = section.querySelector('.swiper-container');

    const swipers = new Swiper(slider, {
      loop: true,
      navigation: {
        nextEl: section.querySelector('.swiper-button-next'),
        prevEl: section.querySelector('.swiper-button-prev'),
      },
    });
  }
}
