import { throttle } from 'throttle-debounce';
import Toggler from '../Toggler';

export default class Header {
  constructor(root) {
    const _ = this;

    _.$root = root;
    _.$burger = _.$root.querySelectorAll('.js-header-burger');
    _.$navigation = _.$root.querySelector('.js-header-nav');

    _.navToggler = new Toggler([[_.$navigation, _.$burger]], {
      escClose: true,
    });

    _.height = null;
    _.lastScrollTop = 0;
    _.isFixed = false;

    _.throttledWatchScroll = throttle(250, _.watchScroll.bind(_));
    _.throttledWatchResize = throttle(250, _.watchResize.bind(_));
    _.boundResetPosition = _.resetPosition.bind(_);
  }

  mount() {
    const _ = this;

    _.navToggler.attach();

    _.watchResize();
    _.watchScroll();

    window.addEventListener('scroll', _.throttledWatchScroll);
    window.addEventListener('resize', _.throttledWatchResize);
  }

  dismount() {
    const _ = this;

    _.navToggler.detach();

    window.removeEventListener('scroll', _.throttledWatchScroll);
    window.removeEventListener('resize', _.throttledWatchResize);
  }

  watchResize() {
    const _ = this;

    _.height = _.$root.offsetHeight;

    if (window.matchMedia('(max-width: 67.49em)').matches) {
      if (_.navToggler.status === 'disabled') {
        _.navToggler.attach();
      }
    } else if (_.navToggler.status === 'enabled') {
      _.navToggler.detach();
    }
  }

  watchScroll() {
    const _ = this;

    const newScrollTop = window.pageYOffset;

    if (!_.isFixed) {
      if (newScrollTop >= _.height) {
        document.body.classList.add('header-is-hidden');
        document.body.classList.remove('header-is-displayed');
      } else {
        document.body.classList.remove('header-is-hidden');
        document.body.classList.add('header-is-displayed');
      }
    }

    if (!_.isFixed && newScrollTop > _.height && newScrollTop < _.lastScrollTop) {
      _.slideDown();
    }

    if (_.isFixed && newScrollTop > 0 && newScrollTop > _.lastScrollTop) {
      _.slideUp();
    }

    if (_.isFixed && newScrollTop <= 0) {
      _.resetPosition();
    }

    _.lastScrollTop = newScrollTop;
  }

  slideDown() {
    const _ = this;

    _.$root.classList.add('slide-down');

    document.body.classList.add('header-is-fixed');
    document.body.classList.add('header-is-displayed');
    document.body.classList.remove('header-is-hidden');

    document.body.style.paddingTop = `${_.height}px`;

    _.isFixed = true;
  }

  slideUp() {
    const _ = this;

    _.$root.classList.remove('slide-down');
    _.$root.classList.add('slide-up');

    document.body.classList.add('header-is-hidden');
    document.body.classList.remove('header-is-displayed');

    _.$root.addEventListener('animationend', _.boundResetPosition);
  }

  resetPosition() {
    const _ = this;

    _.$root.removeEventListener('animationend', _.boundResetPosition);

    document.body.classList.remove('header-is-fixed');
    document.body.style.paddingTop = null;

    _.$root.classList.remove('slide-up');
    _.$root.classList.remove('slide-down');

    _.isFixed = false;
  }
}
