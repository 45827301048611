import Gallery from './Gallery';

const $galleries = document.querySelectorAll('.js-gallery');

if ($galleries.length) {
  for (let i = 0; i < $galleries.length; i++) {
    const $gallery = $galleries[i];
    const $togglers = document.querySelectorAll(`[data-target="#${$gallery.getAttribute('id')}"]`);
    const gallery = new Gallery($gallery, $togglers, {
      appendTo: document.querySelector('.app'),
    });

    for (let j = 0; j < $togglers.length; j++) {
      const $toggler = $togglers[j];
      $toggler.addEventListener('click', () => { gallery.open(); });
    }
  }
}
