/**
* Common interface to load google maps and helper function
*
* Features :
*  # load(callback, withMarkerCluster) : Load google maps with marker cluster or not and execute callback
*  # Custom InfoWindow definition for popin markers
*  # Map styles
*  # fitBounds(map, markers, maxZoom, padding) : Resize the map to current bounds
*/

const MapInterface = {

  callbacks: [],

  GMAP_IS_LOADING: false,
  GMAP_IS_LOADED: false,

  MCLUSTER_IS_LOADING: false,
  MCLUSTER_IS_LOADED: false,

  styles: [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.attraction",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi.business",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.medical",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.school",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ],

  load: function(callback, withMarkerCluster = false) {
    const _ = this;

    let execute = callback;

    if (!_.GMAP_IS_LOADED) {
      _.callbacks.push(callback);
      execute = _.loadGMap.bind(this);
    }

    if (withMarkerCluster && !_.MCLUSTER_IS_LOADED) {
      _.loadMarkerCluster(execute);
    } else {
      execute();
    }
  },

  loadMarkerCluster: function(callback) {
    const _ = this;

    if (!_.MCLUSTER_IS_LOADING && !_.MCLUSTER_IS_LOADED) {
      _.MCLUSTER_IS_LOADING = true;

      const mcScript = document.createElement('script');
      mcScript.type = "text/javascript";
      mcScript.src = markerClusterScriptPath; // Defined inline in the page
      document.body.appendChild(mcScript);

      mcScript.addEventListener('load', function(){
        _.MCLUSTER_IS_LOADING = false;
        _.MCLUSTER_IS_LOADED = true;

        if(typeof callback !== 'undefined') callback();
      });

      return mcScript;
    }
  },

  loadGMap: function() {
    const _ = this;

    if (!_.GMAP_IS_LOADING && !_.GMAP_IS_LOADED && typeof google === 'undefined') {
      _.GMAP_IS_LOADING = true;

      let gScript = document.createElement('script');
      gScript.type = "text/javascript";
      gScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDx4QL-vI_UOmVVECMetbBJ9LooVRZ1KB8&callback=MapInterface.executeCallbacks&language=" + document.querySelector('html').getAttribute('lang').substr(0, 2);
      gScript.addEventListener('load', () => {
        _.GMAP_IS_LOADING = false;
        _.GMAP_IS_LOADED = true;
      }, { once: true });
      document.body.appendChild(gScript);
    }
  },

  executeCallbacks: function() {
    const _ = this;

    for (let i = 0; i < _.callbacks.length; i++) {
      _.callbacks[i]();
    }
  },

  fitBounds: function(map, bounds, maxZoom, padding = 0) {
    if(bounds){
      map.fitBounds(bounds, padding);

      let listener = google.maps.event.addListener(map, "idle", function() {
        if (map.getZoom() > maxZoom) map.setZoom(maxZoom);
        google.maps.event.removeListener(listener);
      });
    }
  },

  getUserMarker: function(map) {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        const marker = new google.maps.Marker({
          title: "Votre position",
          position: pos,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#4285FA',
            fillOpacity: 1.0,
            strokeColor: '#4285FA',
            strokeOpacity: 0.4,
            strokeWeight: 12,
            scale: 7,
          },
        });

        marker.setMap(map);
      });
    }
  }

};

if (typeof window.MapInterface === 'undefined') {
  window.MapInterface = MapInterface;
}

export default MapInterface;
