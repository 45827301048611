export default class Field {
  constructor(root, input) {
    const _ = this;

    _.root = root;
    _.input = input;

    _.boundFocus = _.handleFocus.bind(_);
    _.boundBlur = _.handleBlur.bind(_);
    _.boundChange = _.handleChange.bind(_);
  }

  mount() {
    const _ = this;

    _.input.addEventListener('focus', _.boundFocus);
    _.input.addEventListener('blur', _.boundBlur);
    _.input.addEventListener('change', _.boundChange);

    _.checkEmptyState();
  }

  dismount() {
    const _ = this;

    _.input.removeEventListener('focus', _.boundFocus);
    _.input.removeEventListener('blur', _.boundBlur);
    _.input.removeEventListener('change', _.boundChange);
  }

  handleFocus() {
    const _ = this;

    _.root.classList.remove('is-empty');
  }

  handleBlur() {
    const _ = this;

    if (_.inputIsEmpty()) {
      _.root.classList.add('is-empty');
    }
  }

  handleChange() {
    const _ = this;

    _.checkEmptyState();
  }

  checkEmptyState() {
    const _ = this;

    if (_.inputIsEmpty() && _.input !== document.activeElement) {
      _.root.classList.add('is-empty');
    } else {
      _.root.classList.remove('is-empty');
    }
  }

  inputIsEmpty() {
    const _ = this;

    if (_.input.tagName !== 'SELECT' && (!_.input.value || _.input.value.length === 0)) {
      return true;
    }

    return false;
  }
}
